import { ref } from "vue";

import API from "@/api";

export default function useOrdersRepository() {
  const orders = ref([]);

  const getOrders = async (page, limit) => {
    try {
      let result = await API.Products.Orders.get(page, limit);
      orders.value = result.data.List;
    } catch (error) {
      console.log(error);
    }
  };

  return {
    orders,
    getOrders,
  };
}
