<template>
  <div class="border-gray-200 pb-5 sm:flex sm:items-center sm:justify-between">
    <h3 class="text-lg font-medium leading-6 text-gray-900">Orders Details</h3>
    <div class="flex">
      <div class="mt-3 sm:mt-0 sm:ml-4 sm:mr-4">
        <label for="search_candidate" class="sr-only">Search</label>
        <div class="flex rounded-md shadow-sm">
          <div class="relative flex-grow focus-within:z-10">
            <div
              class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3"
            >
              <SearchIcon class="h-5 w-5 text-gray-400" />
            </div>
            <input
              type="text"
              v-model="search"
              name="search_candidate"
              id="search_candidate"
              class="search block w-full rounded-none rounded-l-md border-gray-300 pl-10 focus:border-indigo-500 sm:hidden 2xl:focus:ring-indigo-500"
              placeholder="Search"
            />
            <input
              type="text"
              name="search_candidate"
              id="search_candidate"
              class="search hidden w-full rounded-none rounded-l-md border-gray-300 pl-10 focus:border-indigo-500 focus:ring-indigo-500 sm:block sm:text-sm"
              placeholder="Search"
            />
          </div>
          <Menu>
            <div class="relative">
              <MenuButton
                type="button"
                class="focus:outline-none relative -ml-px inline-flex items-center rounded-r-md border border-gray-300 bg-gray-50 px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-100 focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500"
              >
                <SortAscendingIcon class="h-5 w-5 text-gray-400" />
                <span class="ml-2">Sort</span>
                <ChevronDownIcon class="ml-2.5 -mr-1.5 h-5 w-5 text-gray-400" />
              </MenuButton>
              <transition
                enter-active-class="transition duration-100 ease-out"
                enter-from-class="transform scale-95 opacity-0"
                enter-to-class="transform scale-100 opacity-100"
                leave-active-class="transition duration-75 ease-out"
                leave-from-class="transform scale-100 opacity-100"
                leave-to-class="transform scale-95 opacity-0"
              >
                <MenuItems
                  class="focus:outline-none absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white text-left shadow-lg ring-1 ring-black ring-opacity-5"
                  role="menu"
                  aria-orientation="vertical"
                  aria-labelledby="sort-menu"
                >
                  <div class="py-1" role="none">
                    <MenuItem>
                      <a
                        href="#"
                        class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                        role="menuitem"
                        >Name</a
                      >
                    </MenuItem>
                    <MenuItem>
                      <a
                        href="#"
                        class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                        role="menuitem"
                        >Date modified</a
                      >
                    </MenuItem>
                    <MenuItem>
                      <a
                        href="#"
                        class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                        role="menuitem"
                        >Date created</a
                      >
                    </MenuItem>
                  </div>
                </MenuItems>
              </transition>
            </div>
          </Menu>
        </div>
      </div>
      <button
        type="button"
        class="focus:outline-none sm:order-0 order-1 ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:ring-purple-500 sm:mr-1"
      >
        <span
          ><FilterIcon class="h-4 w-4 flex-shrink-0 text-gray-400 sm:mr-1"
        /></span>
        Filter
      </button>
      <button
        type="button"
        @click="openCreatePanel"
        class="order-0 focus:outline-none inline-flex items-center rounded-md border border-transparent bg-purple-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-purple-700 focus:ring-purple-500 sm:order-1 sm:ml-3"
      >
        Create
      </button>
    </div>
  </div>

  {{ orders }}

  <!-- create -->
  <TransitionRoot as="template" :show="open">
    <Dialog
      as="div"
      static
      class="fixed inset-0 z-10 overflow-hidden"
      @close="open = false"
      :open="open"
    >
      <div class="absolute inset-0 overflow-hidden">
        <DialogOverlay class="absolute inset-0" />

        <div class="fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
          <TransitionChild
            as="template"
            enter="transform transition ease-in-out duration-500 sm:duration-700"
            enter-from="translate-x-full"
            enter-to="translate-x-0"
            leave="transform transition ease-in-out duration-500 sm:duration-700"
            leave-from="translate-x-0"
            leave-to="translate-x-full"
          >
            <div class="w-screen max-w-2xl">
              <form
                class="flex h-full flex-col overflow-y-scroll bg-white shadow-xl"
              >
                <div class="flex-1">
                  <!-- Header -->
                  <div class="bg-gray-50 px-4 py-6 text-left sm:px-6">
                    <div class="flex items-start justify-between space-x-3">
                      <div class="space-y-1">
                        <DialogTitle class="text-lg font-medium text-gray-900">
                          New Order
                        </DialogTitle>
                        <p class="text-sm text-gray-500">
                          Get started by filling in the information below to
                          create your new order.
                        </p>
                      </div>
                      <div class="flex h-7 items-center">
                        <button
                          type="button"
                          class="focus:outline-none rounded-md bg-white text-gray-400 hover:text-gray-500 focus:ring-2 focus:ring-indigo-500"
                          @click="open = false"
                        >
                          <span class="sr-only">Close panel</span>
                          <XIcon class="h-6 w-6" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                  </div>

                  <!-- Divider container -->
                  <div
                    class="space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0"
                  >
                    <!-- customer name -->
                    <div
                      class="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5"
                    >
                      <div>
                        <label
                          for="product_name"
                          class="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                        >
                          Customer Name
                        </label>
                      </div>
                      <div class="sm:col-span-2">
                        <input
                          type="text"
                          v-model="create.customerName"
                          name="customer_name"
                          id="customer_name"
                          class="block w-full rounded-md border-gray-300 placeholder-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                          placeholder="please enter name"
                        />
                      </div>
                    </div>

                    <!-- order Products -->
                    <div
                      class="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5"
                    >
                      <div>
                        <label
                          for="product_warehouse"
                          class="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                        >
                          Products
                        </label>
                      </div>
                      <div class="flex sm:col-span-2">
                        <el-autocomplete
                          class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                          v-model="productsSearch"
                          placeholder="Product name or number"
                          :fetch-suggestions="querySearch"
                          :trigger-on-focus="false"
                          @select="handleSelect"
                        >
                        </el-autocomplete>

                        <input
                          type="number"
                          min="0"
                          placeholder="Quantity"
                          name="product_quantity"
                          id="product_quantity"
                          v-model="product.quantity"
                          class="block w-1/2 rounded-md border-gray-300 placeholder-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:ml-2 sm:text-sm"
                        />

                        <button
                          type="button"
                          class="focus:outline-none focus:ring-sky-500 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 sm:ml-2"
                          @click="addProduct"
                        >
                          <PlusIcon
                            class="-ml-2 mr-1 h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                          <span>Add</span>
                        </button>
                      </div>
                    </div>
                    <table
                      class="min-w-full divide-y divide-gray-200 p-2"
                      v-if="create.orderProducts.length > 0"
                    >
                      <thead class="bg-gray-50">
                        <tr>
                          <th
                            scope="col"
                            class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                          >
                            Name
                          </th>
                          <th
                            scope="col"
                            class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                          >
                            Quantity
                          </th>
                          <th
                            scope="col"
                            class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                          >
                            Price
                          </th>
                          <th scope="col" class="relative px-6 py-3">
                            <span class="sr-only">Edit</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(product, indx) in create.orderProducts"
                          :key="product.productId"
                          :class="indx % 2 === 0 ? 'bg-white' : 'bg-gray-50'"
                        >
                          <td class="whitespace-nowrap px-6 py-4">
                            <div class="ml-4">
                              <div class="text-sm font-medium text-gray-900">
                                {{ product.name }}
                              </div>
                              <!-- <div class="text-sm text-gray-500">
                                ${{ product.price }}
                              </div> -->
                            </div>
                          </td>
                          <td
                            class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                          >
                            {{ product.quantity }}
                          </td>
                          <td
                            class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                          >
                            ${{ product.price }}
                          </td>
                          <td
                            class="whitespace-nowrap px-6 py-4 text-right text-sm font-medium"
                          >
                            <button
                              type="button"
                              class="text-indigo-600 hover:text-indigo-900"
                              @click="removeProduct(product.productId)"
                            >
                              <span>Delete</span>
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

                <!-- Action buttons -->
                <div
                  class="flex-shrink-0 border-t border-gray-200 px-4 py-5 sm:px-6"
                >
                  <div class="flex justify-end space-x-3">
                    <button
                      type="button"
                      class="focus:outline-none rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:ring-indigo-500"
                      @click="open = false"
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      @click="saveCreateForm"
                      class="focus:outline-none inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:ring-indigo-500"
                    >
                      Create
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import { reactive, ref, onMounted } from "vue";
import {
  Menu,
  MenuButton,
  MenuItems,
  MenuItem,
  Dialog,
  DialogOverlay,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";

import {
  // QrcodeIcon,
  XIcon,
  // TrashIcon,
  // PencilIcon,
  SearchIcon,
  ChevronDownIcon,
  // CheckCircleIcon,
  SortAscendingIcon,
  // OfficeBuildingIcon,
  // ReplyIcon,
  PlusIcon,
  FilterIcon,
  // CollectionIcon,
  // ExclamationIcon,
  // LocationMarkerIcon,
  // ArchiveIcon,
  // CubeIcon,
  // UploadIcon,
} from "@heroicons/vue/outline";

import useOrdersRepository from "@/composable/useOrdersRepository";

import { directive } from "vue-tippy";
import "tippy.js/animations/scale.css";

import API from "@/api";

export default {
  components: {
    Menu,
    MenuButton,
    MenuItems,
    MenuItem,
    // Pagination,
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    XIcon,
    // QrcodeIcon,
    // TrashIcon,
    // PencilIcon,
    SearchIcon,
    ChevronDownIcon,
    SortAscendingIcon,
    // CheckCircleIcon,
    // OfficeBuildingIcon,
    // ReplyIcon,
    PlusIcon,
    FilterIcon,
    // CollectionIcon,
    // ExclamationIcon,
    // LocationMarkerIcon,
    // ArchiveIcon,
    // CubeIcon,
    // UploadIcon,
  },
  directives: {
    tippy: directive,
  },
  setup() {
    const open = ref(false);
    const show = ref(false);
    const productsSearch = ref("");
    const { orders, getOrders } = useOrdersRepository();
    console.log({ orders });

    const create = reactive({
      customerName: "",
      amountPaid: 200,
      orderProducts: [],
    });

    const product = reactive({
      id: null,
      quantity: null,
      name: null,
      price: null,
      number: null,
    });

    const pagination = reactive({
      page: 1,
      pageSize: 10,
      totalResults: 0,
    });

    onMounted(() => {
      getOrders(pagination.page, pagination.pageSize);
    });

    const openCreatePanel = () => {
      open.value = true;
    };

    const querySearch = async (queryString, cb) => {
      let query = await API.Products.Products.find(queryString);
      const products = [];
      query.data.List.forEach((product) => {
        products.push({
          value: product.Name,
          item: product,
        });

        // call callback function to return products
        cb(products);
      });
    };

    const saveCreateForm = () => {
      // saveWarehouse(create);
      open.value = false;
      console.log({ create });

      API.Products.Orders.save(create).then(() => {
        open.value = false;
        getOrders(pagination.page, pagination.pageSize);
        show.value = true;
      });
    };

    const handleSelect = (item) => {
      console.log("aa item", item.item.Id);
      product.id = item.item.Id;
      product.name = item.item.Name;
      product.price = item.item.Price;
      product.number = null;

      console.log("p", product);
    };

    const addProduct = () => {
      console.log({ product });
      create.orderProducts.push({
        productId: parseInt(product.id),
        quantity: parseInt(product.quantity),
        name: product.name,
        price: product.price,
      });

      console.log("s", create.orderProducts);

      productsSearch.value = null;
      product.id = null;
      product.quantity = null;
      product.name = null;
      product.price = null;
    };

    const removeProduct = (id) => {
      console.log(id);
    };

    return {
      open,
      show,
      create,
      orders,
      getOrders,
      product,
      addProduct,
      removeProduct,
      pagination,
      querySearch,
      handleSelect,
      productsSearch,
      saveCreateForm,
      openCreatePanel,
    };
  },
};
</script>
